body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-date-picker__wrapper {
  display: none;
}

p {
  margin: 5px 0;
}

.leaflet-marker-icon {
  color: #fff;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  vertical-align: bottom;
  box-shadow: 2px 1px 4px rgba(0,0,0,0.2);
  border-radius: 30px;
  border-width: 3px;
  border: 2px solid #fff;
  opacity: .9;
  background: #f66;
  transition: 0.15s all ease;
  padding: 1px;
}
.leaflet-marker-icon.up{
  background: rgb(231, 83, 83);
  padding: 3px;
  font-size: 21px;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.scrollbox{
	background:
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
	radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
	radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	
	/* Opera doesn’t support this in the shorthand */
	background-attachment: local, local, scroll, scroll;
}